import React from "react"
import PropTypes from "prop-types"
import Layout from "../../components/layout"
import Helmet from "react-helmet"

import Hero from "../../components/hero"
import ProductCTA from "../../components/cta--product"
import OurStory from "../../components/our-story"
import CenteredCTA from "../../components/cta--centered"
import CTA from "../../components/cta"
import { Row, Col } from "reactstrap"

export default class ProductLandingPage extends React.Component {
  render() {
    const { data } = this.props
    return (
      <React.Fragment>
        <Helmet>
          <title>Plant Based Products</title>
          <meta
            name="description"
            content="Try Raised & Rooted™ plant based burger, chicken and sausage products today. Find great-tasting meat alternatives packed with the nutritive benefits of plants."
          />
        </Helmet>
        <Layout>
          <Hero
            heroDirection="reverse"
            bgImage="/paper-background.jpg"
            //subheadingText1="Raised & Rooted&trade;"
            //subheadingColor1="#006B2D"
            headingText1="Plant Based Burger, Chicken & Sausage Products"
            headingColor1="#006B2D"
            bodyColor="#363636"
            bodyText="We love meat. We love it so much, we decided to make a line of 100% plant-based foods that taste just like it."
            bodyText2="From our Nuggets to our Burgers to our Brats - we promise they'll be worthy of your favorite dipping sauce. It's the power of plant protein without sacrificing the mouthwatering flavor you crave. Or as we like to call it, the best of both worlds."
          >
            <img
              id="productHeroAlt"
              src="/raised-and-rooted-plant-based-nuggets-ground-1050x1082.png"
              alt="Raised And Rooted Plant Based Nuggets And Ground"
            />
          </Hero>
          <div className="w-100">
            <h3 className="cta-container__title p-4 fs-2">OUR PRODUCTS</h3>
          </div>
          <div className="cta-container">
            <Row className="m-0 flex-wrap">
              <ProductCTA
                imgSrc="/raised-and-rooted-plant-based-nuggets-708x721.png"
                altText="Raised And Rooted Plant Based Nuggets"
                headingText1=""
                headingText2="Plant Based Nuggets"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="plant-based-nuggets/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="CHICKENNUGGET1"
              />
              <ProductCTA
                imgSrc="/raised-and-rooted-spicy-plant-based-nuggets-708x721.png"
                altText="Raised And Rooted Spicy Plant Based Nuggets"
                headingText1=""
                headingText2="Spicy Plant Based Nuggets"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="spicy-plant-based-nuggets/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000064"
              />
              <ProductCTA
                tag="NEW!"
                imgSrc="/raised-and-rooted-crispy-plant-based-fillets-708x721.png"
                altText="Raised And Rooted Crispy Plant Based Fillets"
                headingText1=""
                headingText2="Crispy Plant Based Fillets"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="crispy-plant-based-fillets/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000460"
              />
              <ProductCTA
                tag="NEW!"
                imgSrc="/raised-and-rooted-grilled-plant-based-fillets-708x721.png"
                altText="Raised And Rooted Grilled Plant Based Fillets"
                headingText1=""
                headingText2="Grilled Plant Based Fillets"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="grilled-plant-based-fillets/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000453"
              />
              <ProductCTA
                imgSrc="/raised-and-rooted-whole-grain-plant-based-tenders-708x721.png"
                altText="Raised And Rooted Whole Grain Plant Based Tenders"
                headingText1=""
                headingText2="Wholegrain Plant Based Tenders"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="whole-grain-plant-based-tenders/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000071"
              />
              <ProductCTA
                imgSrc="/raised-and-rooted-buffalo-style-plant-based-bites-708x721.png"
                altText="Raised and Rooted Buffalo Style Plant Based Bites"
                headingText1=""
                headingText2="Buffalo Style Plant Based Bites"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="buffalo-style-plant-based-bites/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000293"
              />
              <ProductCTA
                imgSrc="/raised-and-rooted-sweet-barbecue-flavored-plant-based-bites-708x721.png"
                altText="Raised And Rooted Sweet Barbecue Plant Based Bites"
                headingText1=""
                headingText2="Sweet Barbecue Plant Based Bites"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="sweet-barbecue-plant-based-bites/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000286"
              />
              <ProductCTA
                imgSrc="/raised-and-rooted-plant-based-burger-708x721.png"
                altText="Raised And Rooted Plant Based Burger"
                headingText1=""
                headingText2="Plant Based Burger"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="plant-based-burger/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000354"
              />

              <ProductCTA
                imgSrc="/raised-and-rooted-plant-based-ground-708x721.png"
                altText="Raised And Rooted Plant Based Ground"
                headingText1=""
                headingText2="Plant Based Ground"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="plant-based-ground-beef/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000347"
              />
              <ProductCTA
                imgSrc="/raised-and-rooted-bratwurst-style-plant-based-sausage-708x721.png"
                altText="Raised And Rooted Bratwurst Style Plant Based Sausage"
                headingText1=""
                headingText2="Bratwurst Style Plant Based Sausage"
                headingColor2="#006B2D"
                buttonText="View Product"
                buttonLink="plant-based-bratwurst/"
                buttonColor="#FFFFFF"
                buttonBGColor="#006B2D"
                productID="00053400000330"
              />
            </Row>
          </div>

          <Row className="m-0">
            <CenteredCTA
              twoColumn
              order="order-lg-2"
              bgImage="/paper-background.jpg"
              badgeSrc="/hover.svg"
              headingText2="Where to Buy Us"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="No need to search specialty stores. You’ll find our full lineup of plant based products in the refrigerated and frozen aisles of select supermarkets."
              buttonText="Where to Buy Us"
              buttonLink="/where-to-buy/?MM=panel2"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />

            <CenteredCTA
              twoColumn
              order="order-lg-1"
              bgImage="/illustrated-background.svg"
              badgeSrc="/default.svg"
              headingText2="Questions & Answers"
              headingColor2="#006B2D"
              bodyColor="#006B2D"
              bodyText="What’s in Raised & Rooted&trade; products? How are they made? Are they healthy? How do they taste? You’ve got questions, we’ve got answers."
              buttonText="Read Our FAQs"
              buttonLink="/faqs/"
              buttonColor="#FFFFFF"
              buttonBGColor="#006B2D"
            />
          </Row>
        </Layout>
      </React.Fragment>
    )
  }
}
