import React from "react"
import H4 from "./Utility/heading4"
import Button from "./Utility/button"
import { BVLoader, BVInline } from "@tyson-foods/react-component-library"
import Review from "./Review/Review"
import OverlayTag from "./Utility/overlayTag"
import { Col } from "reactstrap"

export default props => (
  <Col className="cta--product mb-5 col-6 col-lg-3 position-relative">
    <BVLoader
      clientName="raisedandrooted"
      siteID="main_site"
      env={process.env.GATSBY_BAZAARVOICE_ENV}
    />
    {props.tag && <OverlayTag title={props.tag} />}

    <div className="media mb-4">
      <a href={props.buttonLink}>
        <img src={props.imgSrc} alt={props.altText} />
      </a>
    </div>
    <div className="cta--product__content position-relative">
      <a href={props.buttonLink}>
        <h4
          className="cta--product__content__title mb-3"
          style={{ color: props.headingColor1 }}
        >
          {props.headingText2}
        </h4>
      </a>

      <div className="mb-3">
        {props.productID && <BVInline productID={props.productID} />}
      </div>
    </div>
    <div className="cta--product__content__bottom pl-4 position-absolute">
      {props.buttonText && (
        <Button
          className="cta--product__content__bottom__button"
          buttonLink={props.buttonLink}
          buttonText={props.buttonText}
          buttonColor={props.buttonColor}
          buttonBGColor={props.buttonBGColor}
        />
      )}
    </div>
  </Col>
)
